.character-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5em;
}

.character-input {
    font-size: 30px;
    width: 9em;
    margin-right: .5em;
}

.search-bar {
    display: flex;
    align-items: stretch;
}

.char-view {
    background-color: var(--bs-gray-200);
    border: solid 1px var(--bs-gray-300);
    text-align: center;
    width: 100%;
    padding: 30px 0;
    font-size: 150px;
    line-height: 150px;
    border-radius: .5rem;
    margin-bottom: 1rem;
}

.char-description {
    code {
        white-space: nowrap;
    }
}

.character-link {
    text-decoration: none;
    background-color: var(--bs-gray-200);
    border: solid 1px var(--bs-gray-300);
    border-radius: .2rem;
    padding: 0 .3em;
}

.how-to-type-emoji {
    code {
        white-space: nowrap;
    }
}
