.converter-wrap{
    padding-top: 30px;
}

.converter-text__wrap{
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #FFF;
    border-radius: 10px;
    border-top-left-radius: 0px;
}
.converter-text__print-area{
    width: 49%;
}
.converter-text__top,
.converter-text__bottom{
    display: flex;
    
}
.converter-text__top{
    justify-content: center;
}
.converter-text__bottom{
    justify-content: space-between;
}
.converter-text__top-btns{
    display: flex;
    justify-content: space-evenly;
    width: auto;
    align-items: center;
    padding-bottom: 15px;
}
.converter-text__top-btns .btn{
    width: 62px;
    background: #0D6EFD0F;
    color: #212529;
    border: none;
}
.converter-text__top-btns .btn:hover{
    /* border: 1px solid red; */
    color: #FFF;
    background-color: #5c636a;;
}
.arrows-icon{
    /* width: 12%; */
    width: 20px;
    margin: 0px 10px;
    cursor: pointer;
}
.copy-icon{
    width: 15px;
    margin: 0px 4px;
    cursor: pointer;
}

.converting-process,
.converting-process-error{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: #0D6EFD0D !important;
    border-radius: 0.375rem;
}
.converting-process span.text-center{
    color: #212529;
}
.converting-process-error{
    display: flex;
    background: rgba(225, 77, 77, 0.05) !important;;
}
/*или через blade props */
.converting-process.active{
    display: flex;
}
.converting-process{
    display: flex;
}

.converter-text__cancel{
    display: flex;
    justify-content: center;
    border-radius: 0.375rem;
    padding-top: 33px;
}
/* Кнопки под областями ввода в случае успешной конвертации */
.converter-text__btns{
    display: flex;
    padding-top: 20px;
}
.converter-text__btns .btn:last-child{
    margin-left: 10px;
}

/* File download */
.converter-file__wrap{
    width: 100%;
}
.form-upload {
    display: grid;
    align-items: center;
    // width: 80vw;
    min-width: 360px;
  }
.form-upload__grag {
    /* display: grid; */
    display: flex;
    position: relative;
    /* height: 50vh; */
    /* min-height: 360px; */
    min-height: 205px;
    height: 205px;
    flex-direction: column;
    align-items: center;

    /* margin-bottom: 25px; */
    color: #FFFFFF;
    font-weight: 500;
    font-size: 18px;
    place-content: center;
    text-align: center;
    justify-items: center;
    background: #0D6EFD0A;
    border: 1px dashed #CED4DA;
    z-index: 99999;
    border-radius: 6px;
}
.form-upload__grag svg {
    width: 10vw;
    /* margin: auto; */
    pointer-events: none;
}
.form-upload__action-choice{
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Класс обёртка для блока с ручной загрукой файла*/
.form-upload__action-choice.disabled{
    pointer-events: none;
}

.form-upload__hint {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #212529;
    width: 70%;
    /* z-index: -111; */
}
.form-upload__grag.active {
    color: #c56fff;
    background-color: hsla(135, 87%, 79%, 0.878);
}
/* Класс обёртка для блока с ручной загрукой файла*/
/* .form-upload__grag.active .form-upload__action-choice{
    pointer-events: none;
} */
  /* Старый Нижний блок с формой и данными файла */
  .form-upload__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .form-upload__title {
    margin-right: 55px;
    font-size: 18px;
    font-weight: 500;
  }
  
  .form-upload__input {
    font-family: inherit;
    font-size: 18px;
  }
  
  .form-upload__input::file-selector-button {
    margin-right: 30px;
    border: none;
    border-radius: 6px;
    padding: 9px 15px;
    font-family: inherit;
    font-weight: inherit;
    transition: background-color 0.2s linear;
    cursor: pointer;
  }
  
  .form-upload__input::file-selector-button:hover {
    background-color: #c56fff;
  }
  
  .form-upload__container {
    width: 360px;
    margin-top: 10px;
    font-size: 16px;
  }
  
  /* .form-upload__grag {
    background: #0D6EFD0A;
    border: 1px dashed #CED4DA;
  } */
  
  .upload-hint,
  .upload-status {
    width: 75%;
  }
  
  .upload-hint {
    display: none;
  }
  
  .upload-hint_visible {
    display: block;
    pointer-events: none;
  }
  
  .upload-loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .upload-loader_visible {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #593273;
  }
  
  .upload-loader__image {
    width: 150px;
    height: 150px;
  }
  
  @media (max-width: 768px) {
    /* .converter-file__upload-area{
      padding: 55px 30px;
    } */
  
    .form-upload__title {
      display: block;
      margin-right: 0;
    }
  
    .form-upload__input::file-selector-button {
      min-width: initial;
      margin-right: 10px;
    }
  }


  /* Стилизаци input file*/
.form-upload__btn{
    padding-top: 20px;
    /* z-index: -111; */
}
.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border: 1px solid #0D6EFD;
    border-radius: 6px;
    background-color: #0D6EFD;
    color: #FFFFFF;
    margin-bottom: 10px; 
    transition: background-color 0.2s linear;
}
.custom-file-upload:hover{
    background-color: #0c51b8;
}

/* Блок с назваонием файла и кнопкой конвертации */
.form-upload__file-info{
    padding-top: 15px;
}
.form-upload__file-info-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.form-upload__file-name{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    background: #0D6EFD0D;
    padding: 13px;
    border-radius: 6px;
}

/* Блок с кнопками Конвертировать снова и Скачать файл */
.form-upload__file-info-btns{
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.form-upload__file-info-btns .btn.btn-success{
    margin-left: 10px;
    background: #1EAA8EFD;
    border: 1px solid #1EAA8EFD
}
.form-upload__file-info-btns .btn.btn-success:hover{

    background: #198754;
}

/* Моб адаптив */
@media(max-width: 450px){
  .converter-text__bottom{
    flex-direction: column;
  }
  .converter-text__print-area{
    width: 100%;
  }
  .converter-text__bottom .converter-text__print-area:last-child{
    margin-top: 15px;
  }
  .converting-process-error{
    min-height: 244px;
  }
  .form-upload{
    min-width: auto;
    display: block;
  }
  .form-upload__file-info-btns{
    flex-direction: column;
    align-items: center;
  }
  .form-upload__file-info-btns #convertAgainBtn,
  .form-upload__file-info-btns #formUploadDownloadBtn{
    font-size: 13px;
    width: 80%
  }
  .custom-file-upload{
    font-size: 13px;
  }
  .form-upload__file-info-btns .btn.btn-success{
    margin-left: 0px;
  }
  .form-upload__file-info-content{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form-upload__file-name{
    margin-bottom: 20px;
    width: 100%;
  }

}