// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Pages
@import "pages/welcome";
@import "pages/json-prettify";
@import "pages/see-non-printable";
@import "pages/char-info";
@import "pages/generate-late-explanation";
@import "pages/json-xml.scss";
