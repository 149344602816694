.revealed {
    font-family: var(--bs-font-monospace);
    background-color: var(--bs-gray-200);
    border: solid 1px var(--bs-gray-300);
    padding: 1em;
    border-radius: 0.25rem;
    margin-bottom: 3em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;

    .revealed-string {
        overflow-wrap: break-word;
        max-width: 100%;
    }

    .revealed-symbol {
        //display: inline-block;
    }

    .kind-space { color: var(--bs-gray-600) }
    .kind-cr {
        color: var(--bs-gray-600);
        font-size: 21px;
        line-height: 20px;
    }
    .kind-lf {
        color: var(--bs-gray-600);
        font-size: 21px;
        line-height: 20px;
    }
    .kind-tab {
        color: var(--bs-gray-600);
        font-size: 21px;
        line-height: 20px;
    }
    .kind-utf-symbol {
        background-color: var(--bs-red);
        color: var(--bs-light);
        border-radius: 0.25rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        margin-right: 1px;

        a {
            color: var(--bs-light);
            text-decoration: none;

            &:hover {
                color: var(--bs-light);
            }
        }
    }

    .break {
        flex-basis: 100%;
        height: 0;
    }

    .end-of-items {
        flex-grow: 1;
    }
}

.tooltip {
    user-select: none;
    pointer-events: none;
}
