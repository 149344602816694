#late-explanation {
    #late-explanation-form {
        background-color: #ffffff;
        margin: 0 auto;
        padding: 20px 40px;
        border-radius: 10px
    }

    h2 {
        text-align: center
    }

    input.invalid {
        background-color: #f1e4e4
    }

    .tab {
        display: none
    }

    //#prevBtn {
    //    background-color: #bbbbbb
    //}

    .step {
        height: 15px;
        width: 15px;
        margin: 0 2px;
        background-color: #bbbbbb;
        border: none;
        border-radius: 50%;
        display: inline-block;
        opacity: 0.5
    }

    .step.active {
        opacity: 1
    }

    .step.finish {
        background-color: #4CAF50
    }

    .all-steps {
        text-align: center;
    }

    .thanks-message {
        display: none
    }

    #result {
        display: none
    }

    #submitBtn {
        display: none;
    }

    #repeat {
        display: none;
    }

    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }


    /* Show the indicator (dot/circle) when checked */

    .container input:checked~.checkmark:after {
        display: block;
    }


    /* Style the indicator (dot/circle) */

    .container .checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }
}
